import React from 'react';
import Contact from '../contact/Contact';
import {
  faServer,
  faBoxes,
  faMemory,
  faLifeRing,
  faUsers,
  faUpload,
  faDollarSign,
  faComment,
  faNetworkWired
} from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

import nass from '../assets/images/nass.png'
import './Storage.css';


import { Helmet}  from "react-helmet";

import Card from '../card/Card';
import ComparisonTable from './ComparisonTable';

const Storage = () =>  {
  window.fbq('track', 'ViewContent', 'Storage');
  return (
    <div className='uk-container uk-margin'>
      <Helmet>
        <title>Storage | Sutton Tech</title>
        <meta name='description' content='Fast, reliable and scalable storage for your terabytes of video editing footage.' />
        <meta name="keywords" content="Best NAS for video editing, Post Production NAS, TrueNAS NAS, UNRAID NAS, Custom-Built NAS, SSD NAS, 10G NAS, NVMe NAS, Specialist NAS, SSD Caching NAS, High Performance NAS, Tiered Storage NAS, Remote Access NAS, Network Attached Storage, Film Industry Storage"></meta>
        <link rel="canonical" href="https://www.suttontech.co.nz/storage" />
      </Helmet>
      <img src={nass} alt='10-bay (left) and 20-bay (right) custom built NAS'></img>
      <div className='uk-grid uk-grid-large label-wrapper' data-uk-grid>
        <div className='uk-width-1-2'><p className="left-nas-label">10-bay, 4K editing NAS</p></div>
        <div className='uk-width-1-2'><p className="right-nas-label">20-bay, 6K editing NAS</p></div>
      </div>
      <div className='uk-margin uk-margin-large-top'>
        <h1 className='uk-heading-small uk-text-center'>Storage Servers</h1>
        <p className='uk-text-lead'>A storage server aka a NAS, (Network-attached storage) is a computer that is dedicated to file storage.</p>
        <p>NASs are ideal for individuals, businesses and education. Lets compare what you may be using to a Sutton Tech NAS.</p>
      </div>

      <ComparisonTable />

      <div>
        <h2 className='uk-heading-x-small uk-margin-top'>Features</h2>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faApple}
              icon2={faWindows}
              title='Mac & Windows'
              bodyParagraph={
                <p>Out of the box we support both Mac and Windows computers.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
          <Card
              icon={faNetworkWired}
              title='Easy Setup'
              bodyParagraph={
                <p>Accessible just like any network drive.</p>
              }
            />
          </div>

        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faBoxes}
              title='Tiered Storage'
              bodyParagraph={
                <p>A Sutton Tech NAS generally has two types of storage, fast solid state storage for working projects and slower high capacity hard drives for archived projects.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faServer}
              title='Expandability'
              bodyParagraph={
                <p>Add more hard drives and/or solid state drives when you need it.</p>
              }
            />
          </div>

        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faMemory}
              title='Upgradability'
              bodyParagraph={
                <p>Upgrade any individual component, only some components can be upgraded on other NASs.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faLifeRing}
              title='Redundancy'
              bodyParagraph={
                <p>Have up to two disk failures before loosing any data.</p>
              }
            />
          </div>

        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faUsers}
              title='Multiple Users'
              bodyParagraph={
                <p>Work simultaneously on the same projects.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faUpload}
              title='Remote Access'
              bodyParagraph={
                <p>Transfer to and from your NAS anywhere over the internet via <a href='https://www.wireguard.com/' className='links'>Wireguard VPN</a>.</p>
              }
            />
          </div>
        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faDollarSign}
              title='Starting from $4995.00'
              bodyParagraph={
                <p>GST inc</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faComment}
              title='Interested?'
              bodyParagraph={
                <p>Flick us a message below!</p>
              }
            />
          </div>
        </div>
      </div>
      <Contact title='Flick us a message'/>
    </div>
  );
}

export default Storage;
