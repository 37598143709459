import React from 'react';
import emailjs from 'emailjs-com';
import Uikit from 'uikit';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import ReCAPTCHA from "react-google-recaptcha"

import ReactGA from 'react-ga';
// import { Helmet}  from "react-helmet";

import './Contact.css';

const env = runtimeEnv();

const initialState = {
  disableSubmit: false,
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  message: '',
  reCapturePass: false
};

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {...initialState};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRecaptureError = this.handleRecaptureError.bind(this);
    this.handleRecaptureSuccess = this.handleRecaptureSuccess.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;

    this.setState({
      [target.name]: target.value
    });
  }

  handleRecaptureError() {
    this.setState({
      reCapturePass: false
    });
  }

  handleRecaptureSuccess() {
    this.setState({
      reCapturePass: true
    });
    let submitButton = document.getElementsByClassName('submit-button')[0];
    submitButton.setCustomValidity('');
  }

  async handleSubmit(event) {
    event.preventDefault();

    let submitButton = document.getElementsByClassName('submit-button')[0];
    if (!this.state.reCapturePass) {
      submitButton.setCustomValidity('Please confirm you are not a robot.')
      submitButton.reportValidity();
      return;
    }

    this.setState({disableSubmit: true})

    const templateParams = {
      ...this.state,
      message: this.state.message.replace('\n', '</br>'),
      location: window.location.pathname
    };

    try {
      await emailjs.send(env.REACT_APP_SERVICE_ID, env.REACT_APP_TEMPLATE_ID, templateParams, env.REACT_APP_USER_ID);
      window.fbq('track', 'Lead', {
        ...this.state,
        location: window.location.pathname
      });
      ReactGA.event({
        category: 'Lead',
        action: 'New Lead submitted'
      });
      this.setState({...initialState});
      window.open('/thanks', '_self');
    } catch (error) {
      Uikit.notification('<p>Whoops something went wrong</p><p>Send us an <a href="mailto: info@suttontech.co.nz">email</a><p>', {status: 'danger', timeout: 999999});
    }
  }

  render() {
    // const helmetSection = (
    //   <Helmet>
    //     <meta name='description' content='Contact Sutton Tech.' />
    //     <title>Contact | Sutton Tech</title>
    //     <link rel="canonical" href="https://www.suttontech.co.nz/contact" />
    //   </Helmet>
    // );
    return (
      <div className='uk-container'>
        <form onSubmit={this.handleSubmit}>
          <fieldset className='uk-fieldset'>
            <h1 className='uk-heading-small uk-text-center uk-margin-large-top' hidden={!this.props.title}>{this.props.title}</h1>
            <div className='uk-margin uk-grid uk-margin-large-top uk-child-width-1-2'>
              <div>
                <input className='uk-input' type='text' maxLength='100' placeholder='First Name *' name='firstname' required value={this.state.firstname} onChange={this.handleInputChange}></input>
              </div>
              <div>
                <input className='uk-input' type='text' maxLength='100' placeholder='Last Name' name='lastname'  value={this.state.lastname} onChange={this.handleInputChange}></input>
              </div>
            </div>
            <div className='uk-margin'>
              <input className='uk-input' type='tel' maxLength='100' placeholder='Phone Number *' name='phone' required value={this.state.phone} onChange={this.handleInputChange}></input>
            </div>
            <div className='uk-margin'>
              <input className='uk-input' type='email' maxLength='100' placeholder='Email *' name='email' required value={this.state.email} onChange={this.handleInputChange}></input>
            </div>
            <div>
              <textarea className='uk-textarea' type='text' maxLength='1000' rows='8' placeholder='Message *' required name='message' value={this.state.message} onChange={this.handleInputChange}></textarea>
            </div>
            <div className='uk-margin uk-container submit'>
              <ReCAPTCHA
                theme='dark'
                sitekey={env.REACT_APP_SITE_KEY}
                onErrored={this.handleRecaptureError}
                onChange={this.handleRecaptureSuccess}
              />
              <button className='uk-button uk-button-primary submit-button' type='submit' disabled={this.state.disableSubmit}>Submit</button>
              <span className='uk-margin-left' uk-spinner='' hidden={!this.state.disableSubmit}></span>
            </div>
          </fieldset>
        </form>
     </div>
    );
  }
}

export default Contact; 