import React from 'react';

import Contact from '../contact/Contact';
import Card from '../card/Card';
import { Link } from 'react-router-dom';
import workstation from '../assets/images/workstation.png'
import './Workstations.css';


import { faMemory, faTools, faDollarSign, faTachometerAlt, faWrench, faLaptopHouse, faComment, faVial } from '@fortawesome/free-solid-svg-icons';
import { Helmet}  from "react-helmet";

const Workstations = () => {
  window.fbq('track', 'ViewContent', 'Workstations');
  return (
    <div className='uk-container uk-margin'>
      <Helmet>
        <title>Workstations | Sutton Tech</title>
        <meta name='description' content='Powerful and reliable custom built PCs for Adobe After Effects, Unreal Engine, DaVinci Resolve and more.' />
        <meta name="keywords" content="Best PC for video editing, Video Editing Computer, Video Editing PC, VFX Workstation, Custom-built PC, Workstations, Premiere Pro PC, After Effects PC,  DaVinci Resolve PC, Unreal Engine PC, Nuke PC, Post-Production PC, Scientific PC, Rackmount PC, Vmix PC, Live Streaming PC, Game Development PC, Audio Engineering PC, Design PC, Photography PC"></meta>
        <link rel="canonical" href="https://www.suttontech.co.nz/workstations" />
      </Helmet>
      <div>
        <div className='image-wrapper'>
          <img src={workstation} alt='4K Editing Workstation' className='image'></img>
        </div>
        <p className='photo-label'>4K editing workstation</p>
      </div>
      <div>
        <h1 className='uk-heading-small uk-text-center'>Workstations</h1>
        <div className='uk-margin'>
          <p className='heading-text'>A Sutton Tech workstation is a computer that is custom built for you to have a great experience.</p>
        </div>

        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faMemory}
              title='Software Optimised'
              bodyParagraph={
                <p>Don't settle for a computer that is not designed for your software! Our workstations are built for optimal performance in Adobe After Effects, Premiere Pro, Photoshop, Lightroom, DaVinci Resolve and more.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faVial}
              title='Stress tested'
              bodyParagraph={
                <p>Our workstations are stress tested before delivery. Giving you peace of mind that your workstation is ready to go when you receive it.</p>
              }
            />
          </div>

        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faTachometerAlt}
              title='Incredible Performance'
              bodyParagraph={
                <p>Forget a power limited and under-cooled laptop, even an M1 Mac cannot compete. Spend less time waiting with the best performance of a workstation.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faLaptopHouse}
              title='Remote Working'
              bodyParagraph={
                <p>Want to work on a laptop but have the power of a workstation? You can! Work remotely on your workstation from a laptop anywhere. See <Link to='/remote-working' className='links'>Remote Working</Link></p>
              }
            />
          </div>

        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faWrench}
              title='Upgradable'
              bodyParagraph={
                <p>All our workstations are built with upgradability in mind, so you don't have to invest everything upfront.</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faTools}
              title='Reliable'
              bodyParagraph={
                <p>At Sutton Tech we only build with high quality components and do not cut corners.</p>
              }
            />
          </div>
        </div>
        <div className='uk-grid uk-grid-match' data-uk-grid>

          <div className='uk-width-1-2@s'>
            <Card
              icon={faDollarSign}
              title='Starting from $2995.00*'
              bodyParagraph={
                <p>For an entry level workstation. *(GST inc)</p>
              }
            />
          </div>

          <div className='uk-width-1-2@s'>
              <Card
                icon={faComment}
                title='Interested?'
                bodyParagraph={
                  <p>Flick us a message below!</p>
                }
              />
            </div>
        </div>
        <Contact title='Flick us a message'/>
      </div>

    </div>
  );
}

export default Workstations;
