import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faReact, faUikit, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import heroku from '../assets/images/logos/heroku.png'

import { ReactComponent as Logo } from '../assets/images/SuttonTechLogoWHITE.svg';


import './Footer.css';

const Footer = () => (
  <div className='footer-wrapper'>
    <div className='uk-container padding-fb'>

      <div className='uk-grid' data-uk-grid>

        <div className='uk-width-1-3@s'>
          <div className='uk-margin-bottom'>
            <Logo width='180px' height='100%' />
            <div className='padding-copy'>Christchurch, New Zealand</div>
            <div className='padding-copy'>
              <div><FontAwesomeIcon icon={faCopyright} /> 2022 Sutton Tech Limited</div>
            </div>
          </div>
        </div>
        
        <div className='uk-width-1-3@s uk-margin-bottom'>
          <div className='uk-margin-small'>Website custom-built by Joe Sutton.</div>
          <a href='https://reactjs.org/' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faReact} className='uk-margin-right' size="2x"/>
          </a>
          <a href='https://getuikit.com/' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faUikit} className='uk-margin-right' size="2x"/>
          </a>
          <a href='https://heroku.com/' target='_blank' rel='noopener noreferrer' className='links'>
            <img src={heroku} alt='Heroku Logo' className='heroku-icon' width='30px'/>
          </a>
        </div>
          

        <div className='uk-width-1-3@s uk-margin-bottom'>
          <a href='https://www.linkedin.com/company/sutton-tech' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faLinkedin} className='uk-margin-right' size="2x"/>
          </a>
          <a href='https://www.facebook.com/suttontech' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faFacebook} className='uk-margin-right' size="2x"/>
          </a>
          <a href='https://www.instagram.com/suttontechnz' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faInstagram} className='uk-margin-right' size="2x"/>
          </a>
          <a href='https://www.youtube.com/channel/UCxanziAkaL6q0_PADuCNFnA' target='_blank' rel='noopener noreferrer' className='links'>
            <FontAwesomeIcon icon={faYoutube} className='uk-margin-right' size="2x"/>
          </a>
          <div className='padding-copy'>info@suttontech.co.nz</div>
          <div className='padding-copy'>021 ART TECH | 021 278 8324</div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;