import React from 'react';

import JoePhoto from '../assets/images/joeProfile.jpg';
import suttonFamily from '../assets/images/suttonFamily.jpg';
import mazda from '../assets/images/mazda.jpg';
import GamingPC from '../assets/images/GamingPC.jpg';
import { Helmet}  from "react-helmet";

import './About.css';

const About = () =>  {
  window.fbq('track', 'ViewContent', 'About');
  return (
    <div className='uk-container uk-margin-medium'>
      <Helmet>
        <title>About | Sutton Tech</title>
        <meta name='description' content='Our vision is for the New Zealand creative industry to thrive by making technology easy.' />
        <meta name="keywords" content="Beginnings, Background, Story, History, Tech enthusiast, Joe Sutton, Computer Builder"></meta>
        <link rel="canonical" href="https://www.suttontech.co.nz/about" />
      </Helmet>
      <h1 className='uk-heading-small uk-text-center'>About</h1>
      <div className='uk-grid uk-grid-match' data-uk-grid>
        <div className='uk-width-1'>
          <div className='uk-card uk-card-secondary'>
            <div className='uk-card-body about-body'>
              <div className='uk-grid uk-grid-small uk-flex-middle' data-uk-grid>

                <div className='uk-width-1-2@s'>
                  <div className='uk-grid uk-grid-small uk-flex-middle' data-uk-grid>
                    <div className='uk-width-1-2'>
                      <img src={JoePhoto} alt='Joe Sutton & Ollie Dog.' className='photo'></img>
                    </div>
                    <div className='uk-width-1-2'>
                      <img src={suttonFamily} alt='Joe & Holly with baby Caleb' className='photo'></img>
                    </div>
                  </div>
                  <div className='uk-margin-top'>
                    <img src={mazda} alt='Mazdaspeed Axela (2007) 2.3L Turbo' className='photo'></img>
                  </div>
                </div>

                <div className='uk-width-1-2@s uk-margin-top'>
                  <h3 className='uk-text-lead vision-statement uk-margin-small-bottom'>Our vision is for the New Zealand creative industry to thrive by making technology easy.</h3>
                  <p>Kia Ora from the founder & director Joe.</p>
                  <p>I am passionate about technology and love seeing creatives thrive. I have previously worked as a software engineering (7 years) and have worked with computer hardware since I was a teenager.</p>
                  <p>Sutton Tech was formed from the combination of my passion for video, love for computers and my career in software development.</p>
                  <p>I am married to Holly and we have a son Caleb and two pooches; Ollie and Benji.</p>
                </div>
            </div>
          </div>
          
          <div className='uk-card uk-card-secondary'>
            <div className='uk-card-body about-body'>
              <div className='uk-grid uk-grid-small uk-flex-middle' data-uk-grid>
                <div className='uk-width-1-2@s'>
                  <img src={GamingPC} alt='Custom-built Gaming PC.' className='photo'></img>
                </div>
                <div className='uk-width-1-2@s'>
                  <h3 className='uk-text-lead vision-statement uk-margin-small-bottom uk-margin-small-top'>Personal Rig</h3>
                  <p>AMD Ryzen 7 5800X CPU</p>
                  <p>Asus ROG Strix Geforce 3080 OC 10G GPU</p>
                  <p>Corsair 32GB DDR4 @ 3733MHz RAM</p>
                  <p>Samsung 980 Pro 1TB x2 SSDs</p>
                  <p>Asus Strix X570-E Motherboard</p>
                  <p>Corsair AX860  Platinum PSU</p>
                  <p>Lian Li O11 Dynamic Evo Case</p>
                  <p>Corsair Hydro X custom water cooling</p>
                  <p>Windows 11 Pro</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default About;
