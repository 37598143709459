import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Card.css';

const Card = ({ bodyParagraph, icon, icon2, title, subTitle }) =>  {
  const secondIcon = icon2 ? <span className='uk-margin-left'><FontAwesomeIcon icon={icon2} size='3x' className='card-icon'/></span> : null;
  return (
    <div className='uk-card uk-card-secondary'>
      <div className='uk-card-header'>
        <div className='uk-grid uk-grid-small uk-flex-middle' data-uk-grid>
          <div className='uk-width-auto'>
            <FontAwesomeIcon icon={icon} size='3x' className='card-icon'/>
            {secondIcon}
          </div>
          <div className='uk-width-expand'>
            <h3 className='uk-card-title uk-margin-remove-bottom'>{title}</h3>
            <p className='uk-text-meta uk-margin-remove-top'>{subTitle}</p>
          </div>
        </div>
      </div>
      <div className='uk-card-body card-body'>{bodyParagraph}</div>
    </div>
  );
}

export default Card;
