import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Gotham-Black.woff';
import './assets/fonts/Gotham-Bold.woff';
import './assets/fonts/Gothic-Bold.woff';
import './index.css';
import Router from './Router';
import '../node_modules/uikit/dist/css/uikit.min.css';
import '../node_modules/uikit/dist/js/uikit.min.js';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-P5W2GJ5'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(<Router />, document.getElementById('root'));