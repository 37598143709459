import React from 'react';
import { Helmet}  from "react-helmet";


const Thanks = () =>  {
  return (
    <div className='uk-container uk-margin'>
      <Helmet>
        <title>Thank you | Sutton Tech</title>
      </Helmet>
      <h1 className='uk-heading-small uk-text-center uk-margin-large-top'>Wow! We are stoked you contacted us. Thank you!</h1>
      <div>
        <p>We will endeavor to get back to you within 48 hours. Keep up the great work and chat soon :)</p>
      </div>
    </div>
  );
}

export default Thanks;
