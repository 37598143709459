import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

import './Nav.css';
import { ReactComponent as Logo } from '../assets/images/SuttonTechLogoWHITE.svg';

const Nav = () => {
  return (
    <div className='uk-position-relative'>

      {/* mobile navbar */}
      <div className='nav-wrapper uk-hidden@m'>
        <div uk-sticky='sel-target: .navbar-wrapper-mobile; cls-active: uk-navbar-sticky'>
          <nav className='uk-navbar uk-navbar-transparent navbar-wrapper-mobile mobile-last-item' data-uk-navbar='dropbar: true;'>
            <div className='uk-navbar-left'>
              <ul className='uk-navbar-nav'>
                <li>
                  <Link to='#'><FontAwesomeIcon icon={faBars} size='2x' className='hamburger'/></Link>
                  <div className='uk-navbar-dropdown' data-uk-toggle>
                    <ul className='uk-nav uk-navbar-dropdown-nav'>
                      <li><Link to='/workstations'>Workstations</Link></li>
                      <li><Link to='/storage'>NAS's</Link></li>
                      <li><Link to='/remote-working'>Remote Working</Link></li>
                      <li><Link to='/about'>About</Link></li>
                      <li><Link to='/contact'><button className='uk-button uk-button-primary contact-button'>Contact</button></Link></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className='uk-navbar-center'>
              <Link to='/' className='uk-navbar-item'>
                <Logo width='180px' height='100%' />
              </Link>
            </div>
          </nav>
        </div>
        <div className='uk-navbar-dropbar custom-dropbar'></div>
      </div>

      {/* Desktop navbar */}
      <div className='nav-wrapper uk-visible@m'>
        <div uk-sticky='sel-target: .navbar-wrapper-desktop; cls-active: uk-navbar-sticky'>
          <nav className='uk-navbar uk-navbar-transparent navbar-wrapper-desktop' data-uk-navbar>
            <div className='uk-navbar-center'>
              <Link to='/' className='uk-navbar-item'>
                <Logo width='220px' height='100%' />
              </Link>
              <ul className='uk-navbar-nav'>
                <li><Link to='/workstations'>Workstations</Link></li>
                <li><Link to='/storage'>NAS's</Link></li>
                <li><Link to='/remote-working'>Remote Working</Link></li>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/contact'><button className='uk-button uk-button-primary contact-button'>Contact</button></Link></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Nav;