import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faTimes, faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';
import './ComparisonTable.css';

const ComparisonTable = () => {
  const dollarIcon = <FontAwesomeIcon icon={faDollarSign} color='#3E6AB3' className='dollar'/>;
  const crossIcon = <FontAwesomeIcon icon={faTimes} color='rgba(219, 0, 0, 0.6)' size='2x'/>;
  const tickIcon = <FontAwesomeIcon icon={faCheck} color='rgba(101, 159, 19, 0.7)' size='2x'/>;
  const limitedIcon = <FontAwesomeIcon icon={faExclamation} color='rgba(226,131,39,.6)' size='2x'/>;

  return (
    <div className='uk-overflow-auto'>
      <table className='uk-table uk-table-middle uk-text-center'>
        <thead>
          <tr>
            <th></th>
            <th className='uk-text-center table-column-heading'>External Drive</th>
            <th className='uk-text-center table-column-heading'>Other NAS</th>
            <th className='uk-text-center table-column-heading'>
              <span className='sutton uk-text-capitalize'>Sutton</span>
              <span className='tech uk-text-capitalize'>Tech </span>
              NAS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='uk-text-left'>Cost</td>
            <td>{dollarIcon}</td>
            <td>{dollarIcon}{dollarIcon}</td>
            <td>{dollarIcon}{dollarIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Customisation</td>
            <td>{crossIcon}</td>
            <td>{crossIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Expandability</td>
            <td>{crossIcon}</td>
            <td>{limitedIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Upgradability</td>
            <td>{crossIcon}</td>
            <td>{limitedIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Redundancy</td>
            <td>{crossIcon}</td>
            <td>{limitedIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Spend less time copying files</td>
            <td>{crossIcon}</td>
            <td>{tickIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Multi User</td>
            <td>{crossIcon}</td>
            <td>{tickIcon}</td>
            <td>{tickIcon}</td>
          </tr>
          <tr>
            <td className='uk-text-left'>Remote Access</td>
            <td>{crossIcon}</td>
            <td>{tickIcon}</td>
            <td>{tickIcon}</td>
          </tr>
        </tbody>
      </table>
      <div className='uk-text-muted uk-margin-bottom'>
        <div><FontAwesomeIcon icon={faExclamation} color='rgba(226,131,39,.6)' size='1x'/> = Yes but with limitations, see below.</div>
      </div>
    </div>
  );
}

export default ComparisonTable;