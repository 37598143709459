import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import GA from './utils/GoogleAnalytics'

import Nav from './nav/Nav';
import Home from './home/Home';
import About from './about/About';
import Storage from './storage/Storage';
import Workstations from './workstations/Workstations';
import RemoteWorking from './remote-working/RemoteWorking';
import NotFound from './NotFound/NotFound';
import Footer from './footer/Footer';
import Contact from './contact/Contact';
import RWH from './rwh/rwh';
import Thanks from './thanks/Thanks';


const TopLevel = () => {
  return (
    <div className='uk-height-viewport'>
      <Router>
        { GA.init() && <GA.RouteTracker /> }
        <Nav />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/workflow'>
            <Home />
          </Route>
          <Route exact path='/storage'>
            <Storage />
          </Route>
          <Route exact path='/remote-working'>
            <RemoteWorking />
          </Route>
          <Route exact path='/workstations'>
            <Workstations />
          </Route>
          <Route exact path='/about'>
            <About />
          </Route>
          <Route exact path='/contact'>
            <Contact title='Flick us a message'/>
          </Route>
          <Route exact path='/remote-workstation-hire'>
            <RWH title='Remote Workstation Hire'/>
          </Route>
          <Route exact path='/thanks'>
            <Thanks title='Thank You'/>
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default TopLevel;