import React from 'react';
import Contact from '../contact/Contact';
import { faLock, faTachometerAlt, faPalette, faDollarSign, faComment, faVideo, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { Helmet}  from "react-helmet";

import Card from '../card/Card';

const RemoteWorking = () =>  {
  window.fbq('track', 'ViewContent', 'Remote Working');
  return (
    <div className='uk-container uk-margin-medium'>
      <Helmet>
        <title>Remote Working | Sutton Tech</title>
        <meta name='description' content="Work untethered with remote desktop software that is designed for video editing." />
        <meta name="keywords" content="Remote Video Editing, Remote Colour Grading, laptop video editing, portable video editing, Remote Desktop Software for Creatives"></meta>
        <link rel="canonical" href="https://www.suttontech.co.nz/remote-working" />
      </Helmet>
      <h1 className='uk-heading-small uk-text-center'>Remote Working</h1>
      <div className='uk-margin'>
        <p>Laptops are great to move around the house, work on site or at a coffee shop but post-production applications are too intensive for a laptop to keep up. With a Sutton Tech remote working subscription you can work on a workstation via a laptop!</p>
      </div>

      <div className='uk-grid uk-grid-match' data-uk-grid>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faApple}
            icon2={faWindows}
            title='Mac & Windows'
            bodyParagraph={
              <p>We support both Mac and Windows computers.</p>
            }
          />
        </div>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faPalette}
            title='4:4:4 colour mode'
            bodyParagraph={
              <p>Designed for Colour Grading with accurate colour.</p>
            }
          />
        </div>

      </div>

      <div className='uk-grid uk-grid-match' data-uk-grid>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faVideo}
            title='Desktop Streaming'
            bodyParagraph={
              <p>Using software we stream the display and audio from your workstation to your laptop, then send the mouse and keyboard instructions back, it's that simple.</p>
            }
          />
        </div>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faWifi}
            title='Network Requirements'
            bodyParagraph={
              <p>Sutton Tech recommends fibre internet with a good router (Wifi 5 or better) and/or a wired connection.</p>
            }
          />
        </div>

      </div>

      <div className='uk-grid uk-grid-match' data-uk-grid>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faLock}
            title='Secure Access'
            bodyParagraph={
              <p>Peer to peer encryption.</p>
            }
          />
        </div>

        <div className='uk-width-1-2@s'>
          <Card
            icon={faTachometerAlt}
            title='Low Latency'
            bodyParagraph={
              <p>60fps, smooth streaming.</p>
            }
          />
        </div>
      </div>
      <div className='uk-grid uk-grid-match' data-uk-grid>

      <div className='uk-width-1-2@s'>
        <Card
          icon={faDollarSign}
          title='Starting from $79.00*'
          bodyParagraph={
            <p>Per month, per user. *(GST inc)</p>
          }
        />
      </div>

      <div className='uk-width-1-2@s'>
        <Card
          icon={faComment}
          title='Interested?'
          bodyParagraph={
            <p>Flick us a message below!</p>
          }
        />
      </div>
    </div>
    <Contact title='Flick us a message'/>
  </div>
  );
}

export default RemoteWorking;
