import React from 'react';
import { Link } from 'react-router-dom';

import './notFound.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const NotFound = () => {
  return (
    <div className='uk-container content'>
      <div className='content-tile'>
        <FontAwesomeIcon icon={faPlug} size="6x" />
        <h1 className='uk-heading-small uk-text-center'>Page Not Found</h1>
        <Link to='/'>
          <div className='uk-button uk-button-primary uk-margin-left action-button'>
            <span className='action-button-text'>Home </span>
            <FontAwesomeIcon className='chevron-right' icon={faChevronRight} />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
