import React from 'react';
import emailjs from 'emailjs-com';
import Uikit from 'uikit';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Card from '../card/Card';

import { faBoxes, faCoins, faTachometerAlt, faGift } from '@fortawesome/free-solid-svg-icons';
import { Helmet}  from 'react-helmet';

import './rwh.css';

const TODAY = new Date();
const env = runtimeEnv();

const initialState = {
  disableSubmit: false,
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  startDate: '',
  endDate: '',
  premiere: false,
  afterEffects: false,
  lightroom: false,
  photoshop: false,
  illustrator: false,
  other: '',
  confirmNetwork: false,
  confirmComputer: false
};

class RWH extends React.Component {

  constructor(props) {
    super(props);
    this.state = {...initialState};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;

    this.setState({
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({disableSubmit: true})

    try {
      await emailjs.send(env.REACT_APP_SERVICE_ID, env.REACT_APP_RWH_TEMPLATE_ID, this.state, env.REACT_APP_USER_ID);
      Uikit.notification('Message Sent', {status: 'success'});
      window.fbq('track', 'RWH Lead', {
        ...this.state,
      });
      this.setState({...initialState});
    } catch (error) {
      Uikit.notification('<p>Whoops something went wrong</p><p>Send us an <a href="mailto: info@suttontech.co.nz">email</a><p>', {status: 'danger', timeout: 999999});
    }
  }

  render() {
    window.fbq('track', 'ViewContent', 'Remote Workstation Hire');
    return (
      <div className='uk-container'>
        <Helmet>
          <title>Remote Workstation Hire | Sutton Tech</title>
          <meta name='description' content='Remotely hire a custom built workstation designed for video editing.' />
          <meta name="keywords" content="Best PC for video editing, Video editing PC hire, "></meta>
          <link rel="canonical" href="https://www.suttontech.co.nz/remote-workstation-hire" />
        </Helmet>
        <form onSubmit={this.handleSubmit}>
          <fieldset className='uk-fieldset'>
            <h1 className='uk-heading-small uk-text-center uk-margin-large-top' hidden={!this.props.title}>{this.props.title}</h1>
            <div className='uk-grid uk-grid-match uk-margin-large-top' data-uk-grid>
              <div className='uk-width-1-2@s'>
                <Card
                  icon={faCoins}
                  title='$95 per day'
                  bodyParagraph={
                    <p>One day = 23 hours. Start & finish time must be with business hours. Includes an optional data loading & off-loading day. (GST inc). </p>
                  }
                />
              </div>
              <div className='uk-width-1-2@s'>
                <Card
                  icon={faGift}
                  title='Free days'
                  bodyParagraph={
                    <p>Our RWH service is in a trial phase so sign up before December 1st and be in to win free days!</p>
                  }
                />
              </div>
            </div>
            <div className='uk-grid uk-grid-match uk-margin-large-top' data-uk-grid>
              <div className='uk-width-1-2@s'>
                <Card
                  icon={faTachometerAlt}
                  title='Experience the best'
                  bodyParagraph={
                    <p>Built for optimal performance in the Adobe Suite.</p>
                  }
                />
              </div>
              <div className='uk-width-1-2@s'>
                <Card
                  icon={faBoxes}
                  title='480GB'
                  bodyParagraph={
                    <p>Remotely accessible, working SSD storage.</p>
                  }
                />
              </div>
            </div>
            <h3 className='uk-margin-large-top inverted-text'>Register your interest</h3>
            <div className='uk-margin uk-grid uk-child-width-1-2'>
              <div>
                <input className='uk-input' type='text' maxLength='100' placeholder='First Name *' name='firstname' required value={this.state.firstname} onChange={this.handleInputChange}></input>
              </div>
              <div>
                <input className='uk-input' type='text' maxLength='100' placeholder='Last Name *' name='lastname' required value={this.state.lastname} onChange={this.handleInputChange}></input>
              </div>
            </div>
            <div className='uk-margin'>
              <input className='uk-input' type='tel' maxLength='100' placeholder='Phone Number *' name='phone' required value={this.state.phone} onChange={this.handleInputChange}></input>
            </div>
            <div className='uk-margin'>
              <input className='uk-input' type='email' maxLength='100' placeholder='Email *' name='email' required value={this.state.email} onChange={this.handleInputChange}></input>
            </div>
            <div>
              <h3 className='inverted-text'>When do you need to hire?</h3>
              <div className='uk-margin uk-grid uk-child-width-1-2'>
                <div>
                  <label className='inverted-text'>Start date *</label>
                  <input className='uk-input' type='date' min={TODAY} name='startDate' required value={this.state.startDate} onChange={this.handleInputChange}></input>
                </div>
                <div>
                  <label className='inverted-text'>End date *</label>
                  <input className='uk-input' type='date' min={TODAY} name='endDate' required value={this.state.endDate} onChange={this.handleInputChange}></input>
                </div>
              </div>
            </div>
            <div className='uk-margin'>
              <h3 className='inverted-text'>What application(s) did you want to use? *</h3>
              <div className='uk-grid uk-margin'>
                  <label className='uk-margin-right st-checkbox inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='premiere' value={this.state.premiere} onChange={this.handleInputChange}></input>Premiere Pro</label>
                  <label className='uk-margin-right st-checkbox inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='afterEffects' value={this.state.afterEffects} onChange={this.handleInputChange}></input>After Effects</label>
                  <label className='uk-margin-right st-checkbox inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='lightroom' value={this.state.lightroom} onChange={this.handleInputChange}></input>Lightroom</label>
                  <label className='uk-margin-right st-checkbox inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='photoshop' value={this.state.photoshop} onChange={this.handleInputChange}></input>Photoshop</label>
                  <label className='uk-margin-right st-checkbox inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='illustrator' value={this.state.illustrator} onChange={this.handleInputChange}></input>Illustrator</label>
              </div>
            </div>
            <div>
              <textarea className='uk-textarea' type='text' maxLength='1000' rows='4' placeholder='Any other requirements?' name='other' value={this.state.other} onChange={this.handleInputChange}></textarea>
            </div>
            <div className='uk-margin'>
              <h3 className='inverted-text'>Lets check your internet speed</h3>
              <iframe title='speedtest' width='100%' height='650px' className='speedtest' src='https://suttontech.speedtestcustom.com'></iframe>
              <h3 className='inverted-text'>Network Requirements for 1080p 60fps</h3>
              <ul className='inverted-text'>
                <li>30Mbps download and 2Mbps upload speed</li>
                <li>5Ghz Wi-Fi / Ethernet / 4G LTE</li>
                <li>No downloads or uploads running in the background</li>
                <li>No Wi-Fi signal boosters, Ethernet over power line devices or mesh Wi-Fi systems</li>
              </ul>
              <p className='inverted-text'>Remote Workstation Hire is only available in New Zealand.</p>
            </div>
            <div className='uk-margin-small'>
              <label className='inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='confirmNetwork' required value={this.state.confirmNetwork} onChange={this.handleInputChange}></input>I confirm my connection meets the network requirements.*</label>
            </div>
            <div>
              <label className='inverted-text'><input className='uk-checkbox uk-margin-small-right' type='checkbox' name='confirmComputer' required value={this.state.confirmComputer} onChange={this.handleInputChange}></input>I confirm my computer meets the following <a target='_blank' rel='noreferrer' href='https://support.parsec.app/hc/en-us/articles/115002702391-Hardware-Requirements-For-Connecting-To-Another-Computer-With-Parsec'>hardware requirements</a>.*</label>
            </div>
            <div className='uk-container submit'>
              <button className='uk-button uk-button-primary submit-button' type='submit' disabled={this.state.disableSubmit}>Submit</button>
              <span className='uk-margin-left' uk-spinner='' hidden={!this.state.disableSubmit}></span>
            </div>
          </fieldset>
        </form>
     </div>
    );
  }
}

export default RWH; 