import React, { useRef } from 'react';
import { Helmet }  from 'react-helmet';
import { Link } from 'react-router-dom';

import Contact from '../contact/Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import builder from '../assets/images/builder.png'
import software from '../assets/images/software.png'
import workflow from '../assets/images/workflow.png'

import copyLess from '../assets/images/slider/copyLess.jpg'
import outdoorWorking from '../assets/images/slider/outdoorWorking.jpg'
import timeline from '../assets/images/slider/timeline.jpg'

import disguise from '../assets/images/logos/disguise.png'
import dneg from '../assets/images/logos/dneg.png'
import feverPitch from '../assets/images/logos/feverPitch.png'
import viva from '../assets/images/logos/viva.png'
import mirage from '../assets/images/logos/mirage.png'
import odv from '../assets/images/logos/odv.png'
import ppgroup from '../assets/images/logos/ppgroup.png'
import theway from '../assets/images/logos/theway.png'
import vidcom from '../assets/images/logos/vidcom.png'
import whitebait from '../assets/images/logos/whitebait.png'
import toby from '../assets/images/logos/toby.png'

import './Home.css';

const Home = () => {
  const contactRef = useRef();

  function handleBookClick() {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const fiveStar = (
    <div>
      <FontAwesomeIcon icon={faStar} size='2x' className='stars'/>
      <FontAwesomeIcon icon={faStar} size='2x' className='stars'/>
      <FontAwesomeIcon icon={faStar} size='2x' className='stars'/>
      <FontAwesomeIcon icon={faStar} size='2x' className='stars'/>
      <FontAwesomeIcon icon={faStar} size='2x' className='stars'/>
    </div>
  );
  return (
    <div className='content'>
      <Helmet>
        <title>Sutton Tech | Powering NZ's creative industry </title>
        <meta name='description' content='Solutions For creative industry including custom-built workstations, network-attached storage (NAS) and remote working.' />
        <meta name="keywords" content="Post Production Technology, IT for creatives, Technology for Screen Industry, Technology for Creative industry, Remote Production House"></meta>
        <link rel="canonical" href="https://www.suttontech.co.nz" />
      </Helmet>
  
      <div className='section section-white'>
        <div className='uk-container'>
          <h1 className='uk-margin-large uk-margin-large-top heading-emphasis'>Powering NZ's Creative Industry</h1>
          <div uk-grid=''>
            <div className='uk-width-1-2@s'>
              <iframe title='Sutton Tech Explainer Video' src='https://www.youtube-nocookie.com/embed/9cigQZwVRFI?showinfo=0&amp;rel=0&amp;modestbranding=1&amp;playsinline=1' width='1920' height='1080' frameBorder='0' allowFullScreen='' uk-responsive=''></iframe>
            </div>
            <div className='uk-width-1-2@s'>
              <h3 className='special left-align text-dark'>New Zealand’s specialist in technology solutions for the creative industry.</h3>
              <p>Welcome to Sutton Tech, Our vision is for the New Zealand creative industry to thrive by making technology easy.</p>
              <p>We serve clients all around New Zealand from Students to Businesses to Hollywood Tier Production Studios.</p>
              <p>Our solutions are relevant to many industries including, Photography, Design, Corporate Video, Film, Virtual Production, Game Development, Live Streaming, Scientific Simulation, Engineering and Surveying.</p>
              <p>Read about our solutions below.</p>
              <button className='uk-button uk-button-primary uk-margin-large-bottom call-to-action' onClick={handleBookClick}>Let's Chat</button>
            </div>
          </div>
        </div>
      </div>

        <div className='uk-cover-container image-height'>
          <img src={timeline} alt='Video Editing Timeline' data-uk-cover/>
          <div className='uk-position-center uk-panel'>
            <span className='heading carousel-heading'>Workstations</span>
            <div>
              <span className='tagline-text'>Playback <span className='uk-text-italic '>Smoother</span></span>
            </div>
            <div>
            <Link to='/workstations'>
              <button className='uk-button uk-button-primary uk-margin-large-bottom call-to-action'>Learn More </button></Link>
            </div>
          </div>
        </div>

        <div className='uk-cover-container image-height'>
          <img src={copyLess} alt='Stack of external hard drives' data-uk-cover/>
          <div className='uk-position-center uk-panel'>
            <span className='heading carousel-heading'>Network Storage</span>
            <div>
              <span className='tagline-text'>Say goodbye to external.</span>
            </div>
            <div>
            <Link to='/storage'>
              <button className='uk-button uk-button-primary uk-margin-large-bottom call-to-action'>Learn More </button></Link>
            </div>
          </div>
        </div>

        <div className='uk-cover-container image-height'>
          <img src={outdoorWorking} alt='Girl on laptop working outdoors' data-uk-cover/>
          <div className='uk-position-center uk-panel'>
            <span className='heading carousel-heading'>Remote Working</span>
            <div>
              <span className='tagline-text'>Work <span className='uk-text-italic '>Untethered</span></span>
            </div>
            <div>
            <Link to='/remote-working'>
              <button className='uk-button uk-button-primary uk-margin-large-bottom call-to-action'>Learn More </button></Link>
            </div>
          </div>
        </div>

        <div className='section'>
          <div className='uk-container'>
            <h1 className='uk-margin-large uk-margin-large-top heading'>Our Clients</h1>
            <div data-uk-grid>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={disguise} alt='Disguise Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={vidcom} alt='Vidcom Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={toby} alt='Toby Ricketts Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={ppgroup} alt='Paterson Pitts Group Logo' className='ppgroup-logo'/>
              </div>
            </div>
            <div data-uk-grid>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={dneg} alt='DNEG Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={mirage} alt='Mirage Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={theway} alt='The Way Studios Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={odv} alt='One Day Video Logo' width='230px'/>
              </div>
            </div>
            <div data-uk-grid>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={viva} alt='Viva La Dirt League Logo'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={whitebait} alt='Whitebait Logo' width='180px'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo'>
                <img src={feverPitch} alt='Fever Pitch Visuals Logo' width='220px'/>
              </div>
              <div className='uk-width-1-2 uk-width-1-4@s client-logo uk-align-center'>
                <h3 className='heading'>And many more...</h3>
              </div>
            </div>
          </div>
        </div>
      

      <div className='uk-margin-large uk-padding-large section section-white'>
        <div className='uk-container'>
          <h1 className='heading-emphasis uk-margin-large'>Why choose Sutton Tech?</h1>
          <div uk-grid=''>
            <div className='uk-width-1-3@s'>
              <img src={software} alt='Adobe Suite' className=''></img>
              <h2 className='heading text-dark uk-margin'>Ultimate performance & stability</h2>
              <p className='no-margins'>Off the self computers & NAS's are not designed for high performance applications and are not tailored to your workflow. You could render in half the time or automate your file copying for archiving. Go custom-built and save hours on your workflow.</p>
            </div>
            <div className='uk-width-1-3@s'>
              <img src={workflow} alt='Joe outside' className=''></img>
              <h2 className='heading text-dark uk-margin'>We don't compromise on quality</h2>
              <p className='no-margins'>We only choose top-quality components and along with our detailed stress testing process we proactively prevent and catch issues before you even receive your solution from us. With Sutton Tech you can be sure our solutions are ready to go and will last.</p>
            </div>
            <div className='uk-width-1-3@s'>
              <img src={builder} alt='Workstation build' className=''></img>
              <h2 className='heading text-dark uk-margin'>We challenge the 'industry standard'</h2>
              <p className='no-margins'>Technology moves fast so we make it our business to keep up with relevant tech for the creative industry. We are willing to challenge the outdated solutions that hide behind calling themselves 'industry standard'.</p>
            </div>
          </div>
          <button className='uk-button uk-button-primary call-to-action uk-margin-large-top' onClick={handleBookClick}>Let's Chat</button>
        </div>
      </div>
  
      <div className='uk-margin-large section'>
        <div className='uk-container'>
          <h1 className='heading uk-margin-large'>FAQs</h1>
          <div>
            <h2 className='heading special left-align'>What does it cost?</h2>
            <p className='uk-margin'>It depends on what application(s) you use so it is difficult to give fixed prices. As an estimate, you’d be looking at $3k and above for a workstation and $5k and above for a NAS.</p>
          </div>
          <div>
            <h2 className='heading special left-align'>How long do I have to wait until it’s installed?</h2>
            <p>Expect your system within 2-3 weeks. For a workstation, we’ll send you detailed instructions on how to set it up yourself.</p>
          </div>
          <div>
            <h2 className='heading special left-align'>Do you ship anywhere in NZ?</h2>
            <p>Yes! we have partnered with out local Pack & Send who provide excellent packing and insurance on all our deliveries.</p>
          </div>
          <div>
            <h2 className='heading special left-align'>Is there a warranty on my workstation or NAS?</h2>
            <p>Yes we provide a 2 year warranty on all our solutions but we build them to last longer. We’ll also guide you through set-up and if you need ongoing technical support, we offer this as an extra cost.</p>
          </div>
        </div>
      </div>

      <div className='uk-margin-top-large uk-padding-large section section-white'>
        <div className='uk-container'>
          <h1 className='heading-emphasis uk-margin-large'>Don’t take our word for it...</h1>
          <div uk-grid=''>
            <div className='uk-width-1-2@s'>
              {fiveStar}
              <p className='center-align'>We were very impressed with the level of detail and expertise that Joe lent to building our extremely efficient NAS system. There’s no one else out there doing what he does - a real personal relationship combined with the technical knowledge to backup what he says and does.</p>
              <h3 className='special heading-no-margin'>One Day Video</h3>
            </div>
            <div className='uk-width-1-2@s'>
              {fiveStar}
              <p className='center-align'>Excellent service and exceptional knowledge around NAS server setups and configuration especially for content creators and video editors that require large data transfer and management. Joe helped us upgrade our server and implement some new protocols. Thanks Joe.</p>
              <h3 className='special heading-no-margin'>MoMac</h3>
            </div>
          </div>
          <div uk-grid=''>
            <div className='uk-width-1-2@s'>
              {fiveStar}
              <p className='center-align'>Joe was an absolute pleasure to do business with. Very helpful with all questions and support in the design process. Then supportive in the delivery stage checking in and making sure the it was stable upon delivery.  Highly recommend, will be returning for all my computer builds.</p>
              <h3 className='special heading-no-margin'>Disguise NZ</h3>
            </div>
            <div className='uk-width-1-2@s'>
              {fiveStar}
              <p className='center-align'>As a media production company we commissioned Sutton Tech to solve some of our editing computer problems. Within one consultation we had a full remedy for the computers, which now run smoothly. We also had their team build three new fit for purchase editing stations, which were custom built for our needs and arrived in really good timing and the presentation of each station was immaculate. Sutton Tech  exceeded our expectations with incredible customer support, exceptional problem solving and the highest level of service. We would highly recommend their services far and wide.</p>
              <h3 className='special heading-no-margin'>The Way Studios</h3>
            </div>
          </div>
          <div>
            <h3 className='special uk-margin-top'>View all our Google reviews <a href='https://www.google.com/search?q=sutton+tech&oq=sutton+tech&aqs=edge.0.69i59l2j69i60j69i65j69i61j69i60j69i65l2j69i64.1213j0j9&sourceid=chrome&ie=UTF-8#lrd=0x6d318b3e8e07b815:0x901678a814003c25,1,,,'>here</a>.</h3>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='uk-container' ref={contactRef}>
          <Contact title='Flick us a message' />
        </div>
      </div>
    </div>
  );
}

export default Home;
